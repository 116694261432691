const Noodl = require('@noodl/noodl-sdk');
const Papa = require('papaparse');

var products;
Papa.parse("/noodl_modules/carbonzero-module/mockdata/produkter.csv", {
	download: true,
	complete: function(results) {
		// Skip first row
		results.data.shift();
		
		// Extract header
		const header = results.data.shift();

		products = [];
		results.data.forEach((row) => {
			const i = {};
			row.forEach((value,index) => {
				const h = header[index];
				if(h !== undefined && h !== '') {
					if(h === 'PROD_RF_NR') i.id = 'Product-' + value;
					else i[h] = value;
				}
			})
			products.push(i);
		})
	}
})

const ProductSearchNode = Noodl.defineNode({
	category:'CarbonZero',
	name:'CarbonZeroProductSearch',
	displayName:'Product Search',
	inputs:{
		'Search String':'string',
	},
	outputs:{
		Results:'array'
	},
	signals:{
		Search:function() {
			var searchString = this.inputs['Search String'].toLowerCase();

			var results = products.filter((p) => {
				console.log(p);
				return p['Tillverkare'].toLowerCase().indexOf(searchString)!==-1
			})

			if(this.outputs.Results === undefined) {
				this.setOutputs({
					Results:Noodl.Array.get()
				})
			}

			this.outputs.Results.set(results);
		}
	}
})

Noodl.defineModule({
    nodes:[
		ProductSearchNode
    ],
    setup() {
    	//this is called once on startup
    }
});